@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Ubuntu:wght@400;700&display=swap');

/*
Font-size: 62.5% is used to bring the default browser font size of 16px, to 10px.
This is used to have human readable “rem” values, since rem depends of the
highest “font-size” property in the DOM. This way 1rem = 10px.

Scroll-behavior is set to smooth for accessibility, there is a media query
further away that automatically disable it based on user system settings 

See Project README.md for more

*/

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: var(--colors-neutral-n700);
  background-color: var(--colors-neutral-n00);
  transition: var(--bg-transition);
}

base-modal {
  z-index: 10;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 7.8rem;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 4rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bordereau-btn {
  background: '#fff' !important;
  color: '#70AD47';
  font-weight: 'bold';
  font-size: '1.3rem';
  border: '2px solid #70AD47';
  line-height: '0.5rem';
  /* height: '3.4rem'; */
}
.apaAndBordereauTable,
.apaAndBordereauTable th,
.apaAndBordereauTable td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.apaAndBordereauTable th {
  text-align: left;
  cursor: pointer;
}
.apaAndBordereauTable > thead > tr > th:first-child {
  text-align: center;
}
.apaAndBordereauTable td {
  padding: 0.8rem;
}

.apaAndBordereauTable {
  table-layout: fixed;
  width: 100%;
  font-size: 1.1rem;
}
.apaAndBordereauTable td {
  width: 25%;
}
.grayBackground {
  background-color: #f9f9f9;
}

.styledBorder {
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  /* height: 3.4rem; */
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.pagination li {
  display: inline;
}
.pagination li {
  cursor: pointer;
}
.active {
  background-color: #70ad47;
  border: #70ad47;
}

.commission_btn {
  font-size: 1.1rem;
}

.recherche_bordereau_btn {
  width: 20rem;
  margin-left: 1rem;
  font-size: 1.1rem;
}

.identity-selected-button {
  width: 22.85rem;
}
.select-input {
  height: 1.9rem;
  height: 4.4rem;
  width: 39rem;
  border-radius: 0.4rem;
  border: solid 0.1rem #b2b2b2;
  font-size: 1.6rem;
}

.civility-select {
  margin-right: 0.8rem;
}

.identity-form-input {
  margin-bottom: 2.4rem;
  width: 46.4rem;
}
.birthdate-input {
  width: 18rem;
}
#country,
#birthDepartment,
#birthCity {
  margin-top: 0.8rem;
}

.combo-input {
  width: 46.4rem;
}
.combo-default-width {
  width: 43rem;
}
.combo-finalisation-projet {
  width: 46.4rem;
}
.zipCode-input {
  width: 15.6rem;
  margin-right: 1.6rem;
}
.zipCode-input-finalisation-projet {
  width: 12.4rem;
}
.toggle-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 45px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  text-align: center;
}

.toggle-button {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.toggle-checkbox:checked + .toggle-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.toggle-button-icon {
  height: 15px;
  width: 15px;
  color: #3a913f;
  position: absolute;
  top: 3px;
  left: 3px;
}
.situation-foyer-selected-button {
  width: 19.6rem;
  height: 5.6rem;
  text-align: center;
}

.situation-foyer-selected-button-margin {
  margin-left: 2.4rem;
}

.situation-foyer-selected-button-margin-top {
  margin-top: 2.4rem;
}

.year-input {
  width: 13.7rem;
  height: 4.9rem;
}
.numeric-input {
  width: 9rem;
  height: 4.8rem;
}

.siren-number {
  width: 30.3rem;
}
.ape-number {
  width: 13.7rem;
}

.aucun-btn {
  height: 4.8rem;
  width: 8rem;
}
.aucun-btn {
  margin: 0;
}
.selection-btn {
  width: 34rem;
}
.disabled-btn {
  background-color: #b1b5b9;
}
.bank-input {
  width: 22.2rem;
}
.bank-input-finalisation {
  width: 34.8rem;
}
.bank-year-input {
  width: 10.6rem;
  justify-content: end;
}

selected-button {
  height: 5.6rem;
  width: 22.4rem;
  font-size: 16px;
}

base-input,
combo-input,
date-input,
gender-input {
  font-size: 1.6rem;
}

:host {
  font-weight: bold;
}

.situation-familiale {
  margin-left: 2.4rem;
}

.bareme-input {
  width: 164px !important;
}

.child-birth-date-input > span {
  text-align: left !important;
}

.assurance-checkbox {
  margin-top: 4.5rem;
}

.lightText {
  color: #b1b5b9;
  font-size: 1.4rem;
}

.identity-type {
  width: 46.4rem;
}

.due-input {
  width: 18.4rem;
}

.additionalDebtComboInput {
  width: 34.8rem !important;
}

.additionalDebtCivility {
  width: 13rem;
}

.city-input-finalisation-projet {
  width: 32.4rem;
}

.table-td-space > td {
  padding-bottom: 3.2rem;
}

.light-custom-input {
  width: 100%;
}

body {
  --main-green-50: #eff8f0;
  --main-green-100: #c9e7ca;
  --main-green-200: #a6d8a9;
  --main-green-300: #83ca87;
  --main-green-400: #69b46c;
  --main-green-500: #268038;
  --main-green-600: #1d602a;
  --main-green-700: #185123;
  --main-green-800: #13411c;
  --main-green-900: #0f3116;

  --light-green-50: #f7fade;
  --light-green-100: #f2f6c8;
  --light-green-200: #edf3b2;
  --light-green-300: #e8ef9c;
  --light-green-400: #dee870;
  --light-green-500: #c4d600;
  --light-green-600: #a3b200;
  --light-green-700: #838f00;
  --light-green-800: #626b00;
  --light-green-900: #414700;

  --dark-green-50: #eff4f2;
  --dark-green-100: #d6e3de;
  --dark-green-200: #bed3cb;
  --dark-green-300: #8eb2a4;
  --dark-green-400: #61917f;
  --dark-green-500: #154734;
  --dark-green-600: #103728;
  --dark-green-700: #0e2f23;
  --dark-green-800: #0b241a;
  --dark-green-900: #071811;

  --neutrals-0: #ffffff;
  --neutrals-50: #f2f3f3;
  --neutrals-100: #dcdee0;
  --neutrals-200: #c7cacd;
  --neutrals-300: #b1b5b9;
  --neutrals-400: #6f757c;
  --neutrals-500: #4b4f54;
  --neutrals-600: #3a3d41;
  --neutrals-700: #292c2e;
  --neutrals-800: #181a1b;
  --neutrals-900: #080809;

  --blue-50: #e5f5fd;
  --blue-100: #e5f5fd;
  --blue-200: #81cae5;
  --blue-300: #68bfe0;
  --blue-400: #0095b9;
  --blue-500: #007590;
  --blue-600: #006078;
  --blue-700: #004f63;
  --blue-800: #003f4f;
  --blue-900: #002f3a;

  --teal-50: #e8f5f3;
  --teal-100: #cbeae9;
  --teal-200: #a6dbda;
  --teal-300: #70c5c3;
  --teal-400: #00a39c;
  --teal-500: #007a75;
  --teal-600: #006661;
  --teal-700: #00514e;
  --teal-800: #003d3a;
  --teal-900: #002827;

  --purple-50: #f6eef6;
  --purple-100: #efdbeb;
  --purple-200: #dfb8d8;
  --purple-300: #cf95c5;
  --purple-400: #bf71b1;
  --purple-500: #9d5190;
  --purple-600: #813a75;
  --purple-700: #652e5b;
  --purple-800: #492142;
  --purple-900: #3b1b35;

  --red-50: #fef2f4;
  --red-100: #fcdbe0;
  --red-200: #f8acb8;
  --red-300: #f57c90;
  --red-400: #ef3654;
  --red-500: #c8102e;
  --red-600: #a70d26;
  --red-700: #860b1f;
  --red-800: #650817;
  --red-900: #440510;

  --yellow-50: #fffde1;
  --yellow-100: #fff3b1;
  --yellow-200: #ffec88;
  --yellow-300: #fedd5e;
  --yellow-400: #fed535;
  --yellow-500: #feca02;
  --yellow-600: #e0b201;
  --yellow-700: #c29a01;
  --yellow-800: #a48201;
  --yellow-900: #a48201;

  --orange-50: #fef4e5;
  --orange-100: #fde9cd;
  --orange-200: #a48201;
  --orange-300: #fabe6a;
  --orange-400: #f9a838;
  --orange-500: #a48201;
  --orange-600: #ce7b07;
  --orange-700: #a76305;
  --orange-800: #7f4c04;
  --orange-900: #623a03;

  --success-500: var(--main-green-500);
  --error-500: var(--red-500);
  --warning-500: #e78a08;
  --information-500: #1068d0;

  --shadow-background: rgb(242, 243, 243);
  --shadow-15: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  --shadow-25: 0 2px 10px 0 rgba(0, 0, 0, 0.25);

  --overlays-background: var(--neutrals-600);
}
